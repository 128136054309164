
















































import FixedTableComponent from "./FixedTableComponent";
export default FixedTableComponent;
